import React from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import First from "./First";
import Second from "./Second";





import Carousel from "./Carousel";
import Accordion from "./Accordion";
import Contact from "./Contact";

import MobileOnlyPage1 from "./MobileOnlyPage1";
import MobileOnlyPage2 from "./MobileOnlyPage2";

const anchors = ["page1", "page2", "page3", "page4", "page5", "page6"];

const winWidth = (window.innerWidth)
const winHeight = (window.innerHeight)
const home = () => (

  <ReactFullpage
    anchors={anchors}
    navigationTooltips={anchors}
    scrollingSpeed={2000}
    licenseKey={'YOUR_KEY_HERE'}
    navigation={false}
    onLeave={(origin, destination, direction, trigger) => {
      if (winWidth > 600 && origin.index === 2 && direction === 'up') {
        const text = document.querySelector('.best-sellers-text-container')
        text.style.transform = 'translateY(-120px)'
        text.style.transition = '2s ease'
        //("Going to section from bottom 2!");
       
      } else if (winWidth > 600 && origin.index === 0 && direction === 'down') {
        // ("Going to section 2 from top!");
        const text = document.querySelector('.best-sellers-text-container')
        text.style.transform = 'translateY(-150px)'
        text.style.transition = '2s ease'
      
      } else if ((winWidth > 600 && winHeight > 801) && origin.index === 1 && direction === 'down') {
        //("Going to section 3 from section 2 top")
        const text = document.querySelector('.best-sellers-text-container')
        text.style.transform = 'translateY(250px)'
        text.style.transition = '2s ease'
     
      } else if ((winWidth > 600 && (winHeight >= 600 && winHeight <= 800)) && origin.index === 1 && direction === 'down') {
        //("Going to section 3 from section 2 top")
        const text = document.querySelector('.best-sellers-text-container')
        text.style.transform = 'translateY(125px)'
        text.style.transition = '2s ease'
    
      }

  


    }

    }
    render={function ({ state, fullpageApi }) {
      return (
        <div style={{height:winHeight}}>
          <First WindowsHeight={winHeight} />
          {winWidth < 600 && <MobileOnlyPage1  WindowsHeight={winHeight}/>}
          {winWidth > 600 && <Second WindowsHeight={winHeight} />}
          {winWidth < 600 && <MobileOnlyPage2 WindowsHeight={winHeight}/>}
          <Carousel WindowsHeight={winHeight} />
          <Accordion  WindowsHeight={winHeight}/>
          <Contact WindowsHeight={winHeight}/>

        </div>
      );
    }}
  />
);
export default home;