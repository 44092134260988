import { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useInView } from 'framer-motion';
import '../styles/BurgerMenu.css'
import video from '../videos/videoFlower3.mp4'


function MoveHeader({ children }) {
    const ref = useRef(null)
    const isInView = useInView(ref, { amount: 1 })
    return (
        <section ref={ref} className="button-animations" >
            <span
                style={{
                    opacity: isInView ? 1 : 0,
                    transform: isInView ? 'translateX(0px)' : 'translateX(-100px)',
                    transition: 'all 0.2s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s',
                }}
            >
                {children}
            </span>
        </section>
    )
}
function Icon1({ children }) {
    const ref = useRef(null)
    const isInView = useInView(ref, { amount: 1 })
    return (
        <section ref={ref} className="button-animations" >
            <span
                style={{
                    opacity: isInView ? 1 : 0,
                    transform: isInView ? 'translateX(0px)' : 'translateX(-100px)',
                    transition: 'all 0.2s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s',
                }}
            >
                {children}
            </span>
        </section>
    )
}

function Icon2({ children }) {
    const ref = useRef(null)
    const isInView = useInView(ref, { amount: 1 })
    return (
        <section ref={ref} className="button-animations" >
            <span
                style={{
                    opacity: isInView ? 1 : 0,
                    transform: isInView ? 'translateX(0px)' : 'translateX(-100px)',
                    transition: 'all 0.2s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s',
                }}
            >
                {children}
            </span>
        </section>
    )
}

function Icon3({ children }) {
    const ref = useRef(null)
    const isInView = useInView(ref, { amount: 1 })
    return (
        <section ref={ref} className="button-animations" >
            <span
                style={{
                    opacity: isInView ? 1 : 0,
                    transform: isInView ? 'translateX(0px)' : 'translateX(-100px)',
                    transition: 'all 0.2s cubic-bezier(0.17, 0.55, 0.55, 1) 0.6s',
                }}
            >
                {children}
            </span>
        </section>
    )
}
function Icon4({ children }) {
    const ref = useRef(null)
    const isInView = useInView(ref, { amount: 1 })
    return (
        <section ref={ref} className="img-animation" >
            <span
                style={{
                    width: "100%",
                    height: "40vh",
                    opacity: isInView ? 1 : 0,
                    transform: isInView ? 'translateX(0px)' : 'translateX(-100px)',
                    transition: 'all 0.2s cubic-bezier(0.17, 0.55, 0.55, 1) 0.8s',
                }}
            >
                {children}
            </span>
        </section>
    )
}
function Message1({ children }) {
    const ref = useRef(null)
    const isInView = useInView(ref, { amount: 1 })
    return (
        <section ref={ref} className="img-animation" >
            <span
                style={{

                    opacity: isInView ? 1 : 0,
                    transform: isInView ? 'translateX(0px)' : 'translateX(200px)',
                    transition: 'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 1s',
                }}
            >
                {children}
            </span>
        </section>
    )
}

function Message2({ children }) {
    const ref = useRef(null)
    const isInView = useInView(ref, { amount: 1 })
    return (
        <section ref={ref} className="img-animation" >
            <span
                style={{

                    opacity: isInView ? 1 : 0,
                    transform: isInView ? 'translateX(0px)' : 'translateX(200px)',
                    transition: 'all 0.8s cubic-bezier(0.17, 0.55, 0.55, 1) 1.5s',
                }}
            >
                {children}
            </span>
        </section>
    )
}
function Message3({ children }) {
    const ref = useRef(null)
    const isInView = useInView(ref, { amount: 1 })
    return (
        <section ref={ref} className="img-animation" >
            <span
                style={{

                    opacity: isInView ? 1 : 0,
                    transform: isInView ? 'translateX(0px)' : 'translateX(200px)',
                    transition: 'all 1s cubic-bezier(0.17, 0.55, 0.55, 1) 1.9s',
                }}
            >
                {children}
            </span>
        </section>
    )
}
function Circle({ children }) {
    const ref = useRef(null)
    const isInView = useInView(ref, { amount: 1 })
    return (
        <section ref={ref}  >
            <span
                style={{
                    opacity: isInView ? 1 : 0,
                    transition: 'all 3s cubic-bezier(0.17, 0.55, 0.55, 1) 3.2s',
                }}
            >
                {children}
            </span>
        </section>
    )
}

export default function BurgerMenu() {
    const [show, setShow] = useState(false);
    const [isTrue, setIsTrue] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
            setIsTrue(prev => !prev)
        }, 5000);
        return () => {
            clearInterval(interval)
        }
    }, [])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let text = 'The most unique plants in the world'.split('')
    return (
        <>

            <FontAwesomeIcon id='burger-menu' icon="fa-solid fa-bars" onClick={handleShow} />

            <Offcanvas show={show} onHide={handleClose} className="w-100" id="canvas-burger">

                <Offcanvas.Header closeButton closeVariant='white' style={{ fontSize: "30px" }}>
                    <Offcanvas.Title>
                        <MoveHeader>
                            <span id="contact-burger">
                                Contact us
                            </span>
                        </MoveHeader>


                    </Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body style={{ overflow: "hidden" }}>
                    <div className='burger-menu-icons-container'>

                        <Icon1>
                            <a href='https://www.facebook.com/panya.srisutham.9'>
                                <p>
                                    <FontAwesomeIcon className='burger-menu-icons' icon="fa-brands fa-facebook-messenger" />
                                </p>
                                <Message1>
                                    <span className='text-burger-yellow'>Feel free to message us</span>
                                </Message1>

                            </a>
                        </Icon1>

                        <Icon2>
                            <a href='https://www.instagram.com/panyas_variegated_plants/'>
                                <p>
                                    <FontAwesomeIcon className='burger-menu-icons' icon="fa-brands fa-instagram" bounce={isTrue} />
                                </p>
                                <Message2>
                                    <span className='text-burger-yellow'>Check our instagram</span>
                                </Message2>
                            </a>
                        </Icon2>

                        <Icon3>
                            <a href='https://wa.me/32472981101'>
                                <p>
                                    <FontAwesomeIcon className='burger-menu-icons' icon="fa-brands fa-whatsapp" />
                                </p>
                                <Message3>
                                    <span className='text-burger-yellow'>Contact us via WhatsApp</span>
                                </Message3>
                            </a>
                        </Icon3>
                    </div>

                    <Icon4>
                        <div className='burger-menu-img'> </div>
                    </Icon4>

                    <Circle>
                        <div className="circle-container">
                            <div className="circle">
                                <p className="circleText">
                                    {text.map((item, index) => (
                                        <span
                                            style={{ transform: `rotate(${index * 9.6}deg)` }}
                                            key={index}
                                        >
                                            {item}
                                        </span>
                                    ))}
                                </p>

                            </div>
                        </div>
                    </Circle>






                </Offcanvas.Body>
                <video id="background-video" autoPlay loop muted>
                    <source src={video} type='video/mp4' />
                </video>
            </Offcanvas>
        </>
    );
}

