import React, { useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useInView } from 'framer-motion'
import MessengerCustomerChat from 'react-messenger-customer-chat';
import '../styles/Contact.css'


function MoveContactItems({ children }) {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  return (
    <section ref={ref} className="animateContact" >
      <span
        style={{
          opacity: isInView ? 1 : 0,
          transform: isInView ? 'translateY(0px)' : 'translateY(200px)',
          transition: 'all 1.2s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
        }}
      >
        {children}
      </span>
    </section>
  )
}
function MoveIcon1({ children }) {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  return (
    <section ref={ref} className="animateContact" >
      <span
        style={{
          opacity: isInView ? 1 : 0,
          transform: isInView ? 'translateY(0px)' : 'translateY(50px)',
          transition: 'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.6s',
        }}
      >
        {children}
      </span>
    </section>
  )
}
function MoveIcon2({ children }) {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  return (
    <section ref={ref} className="animateContact" >
      <span
        style={{
          opacity: isInView ? 1 : 0,
          transform: isInView ? 'translateY(0px)' : 'translateY(50px)',
          transition: 'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.9s',
        }}
      >
        {children}
      </span>
    </section>
  )
}

function MoveIcon3({ children }) {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  return (
    <section ref={ref} className="animateContact" >
      <span
        style={{
          opacity: isInView ? 1 : 0,
          transform: isInView ? 'translateY(0px)' : 'translateY(50px)',
          transition: 'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 1.2s',
        }}
      >
        {children}
      </span>
    </section>
  )
}

function MoveFooter({ children }) {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  return (
    <section ref={ref} className="animateContact"  >
      <span
        style={{
          position: "absolute",

          left: 0,
          right: 0,
          bottom: 0,
          transform: isInView ? 'translateY(0)' : 'translateY(70px)',
          transition: 'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 1s',


        }}
      >
        {children}
      </span>
    </section>
  )
}





export default function Contact(props) {

  const date = new Date()
  const year = date.getFullYear()



  return (
    <div className='section' id='section-contact' style={{ height: props.WindowsHeight }}>


      <div id='contact-hash' className='contact-container'>
        <MoveContactItems>
          <div className='div-1-contact'>
            <p className='newsletter-textGreen'>NEWSLETTERS</p>
            <p className='newsletter-text'>Subscribe and get</p>
            <p className='newsletter-text'> the latest news </p>
            <p className='newsletter-text'>and updates</p>

            <div className='form-container'>
              <input id='input-contact' disabled type='text' placeholder='Type Your E-Mail' />
              <a href='/Contact'>
                <div className='green-square-contact'><FontAwesomeIcon id="arrow-form" icon="fa-solid fa-angle-right" /></div>
              </a>
            </div>

          </div>
        </MoveContactItems>

        <MoveContactItems>
          <div className='div-2-contact'>
            <p><a className='contacts-links' href='https://www.instagram.com/panyas_variegated_plants/'><FontAwesomeIcon icon="fa-brands fa-instagram" /> @panyas_variegated_plants</a> </p>
            <p><a className='contacts-links' href='https://www.facebook.com/messages/t/100005473301234/'><FontAwesomeIcon icon="fa-solid fa-envelope" /> info@panyasvariegatedplants.eu </a> </p>
            <p><a className='contacts-links' href='https://wa.me/32472981101'><FontAwesomeIcon icon="fa-solid fa-phone" />+32(0)472 98 11 01 </a> </p>
          </div>
        </MoveContactItems>

      </div>



      <MoveFooter>


        <footer id='footer'>
          <div className='footer-left-text'>
            <span id='text-left-footer' className='footer-white-text'>© {year}</span>
            <span className='footer-text-yellow'>PANYA'S VARIEGATED  PLANTS </span>



            <span id='right-reserved' className='footer-white-text'>All Rights Reserved</span>
          </div>


          <div className='footer-icons'>
            <MoveIcon1>

              <a href='https://www.facebook.com/panya.srisutham.9'>
                <span>
                  <FontAwesomeIcon icon="fa-brands fa-facebook-messenger" />
                </span>
              </a>




            </MoveIcon1>
            <MoveIcon2>
              <a href='https://www.instagram.com/panyas_variegated_plants/'>
                <span>
                  <FontAwesomeIcon icon="fa-brands fa-instagram" />
                </span>
              </a>
            </MoveIcon2>
            <MoveIcon3>
              <a href='https://wa.me/32472981101'>
                <span>
                  <FontAwesomeIcon icon="fa-brands fa-whatsapp" />
                </span>
              </a>
            </MoveIcon3>
          </div>

        </footer>

        <MessengerCustomerChat
          pageId="106751622492629"
          appId="215971755540323"
        />
      </MoveFooter>


    </div >
  )
}
