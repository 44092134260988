import React from 'react'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import '../styles/Second.css'

function SectionImgUp({ children }) {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  return (
    <section ref={ref} className="animeImage" >
      <span
        style={{
          transform: isInView ? 'translateY(0)' : 'translateY(100px)',
          transition: 'all 1.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s',
        }}
      >
        {children}
      </span>
    </section>
  )
}
function SectionImgUp2({ children }) {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  return (
    <section ref={ref} className="animeImage" >
      <span
        style={{
          transform: isInView ? 'translateY(0)' : 'translateY(300px)',
          transition: 'all 1.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s',
        }}
      >
        {children}
      </span>
    </section>
  )
}



export default function Second() {


  return (
    <div className='section' id="section-2-desktop">

      <div className="section1_2Container-desktop">


        <div className="section2-father">

          <SectionImgUp>
            <div className="section2-desktop">
              <div className="section2-text">
                <h4 className="perfect">Lorem ipsum</h4>
                <h2 className='plant-h2'>Lorem</h2>
                <p className="plants-text p-1">Lorem ipsum dolor sit amet.</p>
                <p className="plants-text p-1">  Lorem ipsum dolor sit amet.</p>

              </div>

              <div className="section2-text-2">
                <a href='/Shop'>
                  <p className="shop-text-plant p-3">Lorem ipsum.</p>
                </a>
              </div>

            </div>
          </SectionImgUp>

        </div>

        <div className='section2_1-father'>
          <SectionImgUp2>

            <div className="section2_1-desktop">

              <div className="section2-text">

                <h4 className="perfect">Lorem ipsum dolor sit amet.</h4>
                <h2 className='plant-h2'>LOREM</h2>
                <p className="plants-text p-1">Lorem ipsum dolor sit amet. </p>
                <p className="plants-text p-1"> Lorem ipsum dolor sit amet. </p>
                <p className="plants-text p-1"> Lorem ipsum dolor sit amet. </p>

              </div>
              <div className="section2-text-2">
                <a href='/Shop'>
                  <p className="shop-text-plant p-3">LOREM</p>
                </a>
              </div>

            </div>

          </SectionImgUp2>
        </div>

      </div>

      <div className='best-sellers-text-container'>

        <div className='best-sellers-text-container-left'>
          <p className='best-sellers-text'>LOREM</p>
          <p className='best-sellers-big-text'>Lorem ipsum dolor sit amet.  </p>
                  <p className='best-sellers-big-text'> Lorem ipsum dolor sit amet.</p>
        </div>

        <div className='best-sellers-text-container-smallText'>
          <p>Lorem ipsum dolor sit amet. </p>
          <p>Lorem ipsum dolor sit amet.</p>
          <p> Lorem ipsum dolor sit amet.</p>
        </div>

      </div>

    </div>
  )

}
