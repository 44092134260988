import React, {  useRef, useState } from 'react'

import { useInView } from 'framer-motion'
import '../styles/Accordion.css'


function MoveTextAccordion({ children }) {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  return (
    <section ref={ref} className="animeTextAccordion" >
      <span
        style={{
          zIndex: 500,
          transform: isInView ? 'translateY(0px)' : 'translateY(300px)',
          transition: 'all 1.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0s',
        }}
      >
        {children}
      </span>
    </section>
  )
}



export default function Accordion(props) {
  const [rotate, setRotate] = useState(false)
  const [rotate2, setRotate2] = useState(false)
  const [rotate3, setRotate3] = useState(false)
 

 


  function rotatePlus() {
    setRotate(prev => !prev)
  }
  function rotatePlus2() {
    setRotate2(prev => !prev)
  }

  function rotatePlus3() {
    setRotate3(prev => !prev)
  }

  const rotateMinus90 = {
    transform: rotate ? "rotate(-90deg)" : "rotate(0deg)",
    transition: "0.5s ease"
  }
  const rotateMinus90_2 = {
    transform: rotate2 ? "rotate(-90deg)" : "rotate(0deg)",
    transition: "0.5s ease"
  }
  const rotateMinus90_3 = {
    transform: rotate3 ? "rotate(-90deg)" : "rotate(0deg)",
    transition: "0.5s ease"
  }

  const hideAndShow = {
    height: rotate ? "70px" : "0vh"
  }
  const hideAndShow_2 = {
    height: rotate2 ? "65px" : "0vh"
  }
  const hideAndShow_3 = {
    height: rotate3 ? "70px" : "0vh"
  }



  return (
    <div className='section' id='section-accordion' style={{height:props.WindowsHeight}} >


      <div className="section-accordion-container-main">

        <MoveTextAccordion>
          <div className='accordion-text-container'>
            <p className='goal-and-vision'>OUR GOAL & VISION</p>
            <p className='best-plant'>The best plant growers </p>
            <p className='best-plant'>of Benelux</p>
          </div>
        </MoveTextAccordion>


        <div className='accordion-div-text-container' onClick={rotatePlus}>

          <div className='accordion-div-text-container-child'>
            <p className='accordion-div-text-white'>We are sure that we always buy high quality and healthy plants.</p>
            <span className='accordion-minus'></span>
            <span style={rotateMinus90} className='accordion-plus'></span>

          </div>
          <div style={hideAndShow} className='hidden-div'>
            <p className="hidden-div-p m2">Throughout the last years we explored the plant market
              and we built up very good contacts with the best plant growers of Benelux. That’s why we are sure
              that we always buy high quality and healthy plants.</p>
          </div>

        </div>

        <div className='accordion-div-text-container' onClick={rotatePlus2}>

          <div className='accordion-div-text-container-child'>
            <p className='accordion-div-text-white'>We do not use Genetically Modified Organisms (GMO's).</p>
            <span className='accordion-minus'></span>
            <span style={rotateMinus90_2} className='accordion-plus'></span>

          </div>
          <div style={hideAndShow_2} className='hidden-div'>
            <p className="hidden-div-p m-2">We do not use Genetically Modified Organisms (GMO)
              to grow our plants. We only use spaghnum moss, fluval stratum, perlite and akadama to grow our plants.</p>
          </div>

        </div>

        <div className='accordion-div-text-container' onClick={rotatePlus3}>

          <div className='accordion-div-text-container-child'>
            <p className='accordion-div-text-white'>Our plants are safely packed for shipping worldwide.</p>
            <span className='accordion-minus'></span>
            <span style={rotateMinus90_3} className='accordion-plus'></span>

          </div>
          <div style={hideAndShow_3} className='hidden-div'>
            <p className="hidden-div-p m-2">Our plants are safely packed for shipping worldwide (EU, USA, ASIA...). FShipping by air cargo guarantees temperature between 15 and 25C to keep
              our plants in a good condition.</p>
          </div>

        </div>
      </div>


    </div>
  )
}
