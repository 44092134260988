import React from 'react'

import { useRef } from 'react'
import { useInView } from 'framer-motion'
import '../styles/MobileOnlyPage2.css'

function SectionImage({ children }) {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  return (
    <section ref={ref} className="animeImage" >
      <span
        style={{
          transform: isInView ? 'scale(1.2)' : 'scale(1)',
          transition: 'all 1.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
        }}
      >
        {children}
      </span>
    </section>
  )
}

function LineHeight1({ children }) {
  const ref = useRef(null)
  const isInView = useInView(ref, { amount: 1 })
  return (
    <section ref={ref} className="animeTextHeight" >
      <span
        style={{
          transform: isInView ? "translateY(0)" : "translateY(100px)",
          transition: 'all 1s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s',
        }}
      >
        {children}
      </span>
    </section>
  )
}

function LineHeight2({ children }) {
  const ref = useRef(null)
  const isInView = useInView(ref, { amount: 1 })
  return (
    <section ref={ref} className="animeTextHeight" >
      <span
        style={{
          transform: isInView ? "translateY(0)" : "translateY(100px)",
          transition: 'all 1s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
        }}
      >
        {children}
      </span>
    </section>
  )
}
function LineHeight3({ children }) {
  const ref = useRef(null)
  const isInView = useInView(ref, { amount: 1 })
  return (
    <section ref={ref} className="animeTextHeight" >
      <span
        style={{
          transform: isInView ? "translateY(0)" : "translateY(100px)",
          transition: 'all 1s cubic-bezier(0.17, 0.55, 0.55, 1) 0.6s',
        }}
      >
        {children}
      </span>
    </section>
  )
}

function LineHeight4({ children }) {
  const ref = useRef(null)
  const isInView = useInView(ref, { amount: 1 })
  return (
    <section ref={ref} className="animeTextHeight" >
      <span
        style={{
          transform: isInView ? "translateY(0)" : "translateY(100px)",
          transition: 'all 1s cubic-bezier(0.17, 0.55, 0.55, 1) 0.7s',
        }}
      >
        {children}
      </span>
    </section>
  )
}
function LineHeight5({ children }) {
  const ref = useRef(null)
  const isInView = useInView(ref, { amount: 1 })
  return (
    <section ref={ref} className="animeTextHeight" >
      <span
        style={{
          transform: isInView ? "translateY(0)" : "translateY(100px)",
          transition: 'all 1s cubic-bezier(0.17, 0.55, 0.55, 1) 0.8s',
        }}
      >
        {children}
      </span>
    </section>
  )
}

function LineHeightH1({ children }) {
  const ref = useRef(null)
  const isInView = useInView(ref, { amount: 1 })
  return (
    <section ref={ref} className="animeTextHeight" >
      <span
        style={{
          transform: isInView ? "translateY(0)" : "translateY(100px)",
          transition: 'all 1s cubic-bezier(0.17, 0.55, 0.55, 1) 0s',
        }}
      >
        {children}
      </span>
    </section>
  )
}

function LineHeightH2({ children }) {
  const ref = useRef(null)
  const isInView = useInView(ref, { amount: 1 })
  return (
    <section ref={ref} className="animeTextHeight" >
      <span
        style={{
          transform: isInView ? "translateY(0)" : "translateY(100px)",
          transition: 'all 1s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s',
        }}
      >
        {children}
      </span>
    </section>
  )
}

export default function MobileOnlyPage2(props) {

  return (
    <div className='section' id="section-mobile-page2" style={{ height: props.WindowsHeight }}>
      <div className='section2_1-container'>
        <div className="mobile-img-container-page2">
          <SectionImage>
            <div className="section2_1-image">
            </div>
          </SectionImage>
        </div>

        <div className="section2-text-page2-mobile">
          <div style={{ overflow: "hidden", zIndex: 200, }}>

            <LineHeightH1>
              <h4 className="perfect">25% OF OUR PLANTS IS</h4>
            </LineHeightH1>
            <LineHeightH2>
              <h2 className='plant-h2'>Cuttings</h2>
            </LineHeightH2>

            <LineHeight1>
              <p className="plants-text-mobile">From 25% of our plants we make</p>
            </LineHeight1>

            <LineHeight2>
              <p className="plants-text-mobile">cuttings. We take care of them until</p>
            </LineHeight2>

            <LineHeight3>
              <p className="plants-text-mobile">they are fully rooted and ready to</p>
            </LineHeight3>

            <LineHeight4>
              <p className="plants-text-mobile">grow themselves   and ready to be</p>
            </LineHeight4>


            <LineHeight5>
              <p className="plants-text-mobile">  shipped to their new owners</p>
            </LineHeight5>
          
          </div>
        </div>

        <div className="section2-text-2">
          <a href='/Shop'>
            <p className="shop-text-plant p-3">OUR CUTTINGS</p>
          </a>
        </div>
      </div>
    </div>
  )
}