import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import App from './scripts/app';
library.add(fas, fab)

const root = ReactDOM.createRoot(document.getElementById('react-root'));
root.render(
  <BrowserRouter>
    <App/>
  </BrowserRouter>
);
