import { animate, motion, useMotionValue, useTransform } from 'framer-motion';
import Homepage from './Homepage';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import '../styles/App.css';
import logo from '../images/logo_transparent2.png';
import BurgerMenu from './BurgerMenu';


export function TikTokDropdown() {
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const handleMouseEnter = () => {
        setDropdownOpen(true);
    };

    const handleMouseLeave = () => {
        setDropdownOpen(false);
    };

    const dropdownContentStyle = {
        display: isDropdownOpen ? 'block' : 'none',
        position: 'absolute',
        top: '100%',
        left: 0,
        backgroundColor: '#124734', 
        minWidth: '160px',
        boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.2)',
        zIndex: 1,
        padding: '8px', 
        color: 'white', 
        textDecoration: 'none', 
        textAlign: 'left',
    };

    return (
        <div className="dropdown" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <a href='#'>
                <span> <FontAwesomeIcon icon={faTiktok} /></span>
            </a>
            <div className="dropdown-content" style={dropdownContentStyle}>
                <a href='https://www.tiktok.com/@panyasvariegatedplants/' style={{ display: 'block', marginBottom: '8px' }}>@panyasvariegatedplants</a>
                <a href='https://www.tiktok.com/@panyasvariegatedplantseu/' style={{ display: 'block' }}>@panyasvariegatedplantseu</a>
            </div>
        </div>
    );
}

export default function App() {
    const [load2, setLoad2] = useState(false);
    const count = useMotionValue(0);
    const rounded = useTransform(count, Math.round);

    useEffect(() => {
        const animation = animate(count, 100, { duration: 5.5 });
        return animation.stop;
    }, [count]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoad2(true);
        }, 5500);

        return () => {
            clearTimeout(timer);
        };

    }, []);

    return (
        <div>
            <motion.nav className='navigation-container'
                initial={{ opacity: 0, y: -200 }}
                animate={{ y: [-200, 0], opacity: 1 }}
                transition={{ duration: 2, delay: 4.5 }}
            >
                <div className='desktop-shop-contact d-none d-sm-flex'>
                    <a href='/Shop'><p className='desktop-shop'>SHOP</p></a>
                    <a href='/Contact'><p>CONTACT</p></a>
                    <span id="hidden-contact" href='/Contact'><p>CONTACT</p></span>
                </div>

                <span className='d-sm-none'>
                    <FontAwesomeIcon id="bars" icon="fa-solid fa-bars " />
                </span>

                <div className='logo-container'>
                    <img src={logo} alt='logo' className='logo' />
                </div>

                <span id="icons-header-mobile" className="d-block d-sm-none">
                    <BurgerMenu />
                </span>

                <div className='icons-desktop d-none d-sm-flex'>
                    <TikTokDropdown />
                    <a href='https://www.facebook.com/panya.srisutham.9'><span><FontAwesomeIcon id='facebook' icon="fa-brands fa-facebook-messenger" /></span></a>
                    <a href='https://www.instagram.com/panyas_variegated_plants/'> <span><FontAwesomeIcon id="instagram-desktop" icon="fa-brands fa-instagram" /></span></a>
                  
                    <a href='https://wa.me/32472981101'><span>  <FontAwesomeIcon icon="fa-brands fa-whatsapp" /></span></a>
                    <a href='/Shop'><span><FontAwesomeIcon icon="fa-solid fa-cart-plus" /></span></a>
                </div>
            </motion.nav>

            <motion.div
                initial={{ opacity: 1, zIndex: 5 }}
                animate={{ opacity: 0, zIndex: -5 }}
                transition={{ delay: 5.3, duration: 3 }}
                className='loader'>{rounded}
            </motion.div>

            {load2 && <Homepage />}
        </div>
    );
}
