import React, { useRef, useEffect, useState } from 'react'
import { useInView } from 'framer-motion';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import '../styles/Carousel.css'

function MoveGreenBackground({ children }) {

    
    const ref = useRef(null)
    const isInView = useInView(ref, { amount: 1 })
    return (
        <section ref={ref} className="animeImage" >
            <span
                style={{
                    zIndex: "-1",
                    backgroundColor: "rgb(14, 50, 36)",
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    left: 0,
                    padding: isInView ? "20vh" : "0",

                    transition: 'all 1.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s',
                }}
            >
                {children}
            </span>
        </section>
    )
}

function MoveMobileText({ children }) {
    const ref = useRef(null)
    const isInView = useInView(ref, { amount: 1 })
    return (
        <section ref={ref} className="animeText" >
            <span
                style={{
                    position: "absolute",
                    top: "10vh",
                    width: "80%",
                    zIndex: -2,
                    transform: isInView ? 'translateY(-10vh)' : 'translateY(30vh)',
                    transition: 'all 1.2s cubic-bezier(0.17, 0.55, 0.55, 1) 0.6s',
                }}
            >
                {children}
            </span>
        </section>
    )
}


export default function Carousel(props) {
    const [images, setImages] = useState([]);
    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        cache: 'no-store'
    };
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/Carrousel/Index`, requestOptions)
            .then((response) => response.json())
            .catch(error => console.log(JSON.stringify(error)))
            .then((data) => {
                setImages(data);
            })  
    }, []);

    return (
        <div className='section' id="section-carousel" style={{ height: props.WindowsHeight }}>
           
            <Splide aria-label="Products" id="carousel"
                options={{
                    perPage: 5,
                    perMove: 1,
                    speed: 800,
                    pagination: false,
                    gap: '20px',
                    breakpoints: {
                        1500: {
                            perPage: 3
                        },
                        950: {
                            perPage: 2
                        },
                        600: {
                            perPage: 1,
                        },
                    }
                }}
            >
                {images ? images.map((image) => (
                    image.isInCarrousel ? (
                        <SplideSlide key={image.id}>
                            <div className="carousel-img-container">
                                <img src={`${process.env.REACT_APP_API_URL}/api/files?id=${image.id}`} alt={image.title} />
                                <span className="carousel-img-container-text">{image.title}</span>
                            </div>
                        </SplideSlide>
                    ) : null
                )) : ""}
            </Splide>
            <MoveGreenBackground>
                <div className='green-background'></div>
            </MoveGreenBackground>
        </div>
    );
}
